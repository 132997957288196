<template>
  <v-app style="width: 100%;">
    <v-snackbar
      :timeout="3000"
      :value="true"
      absolute
      top
      color="success"
      right
      text
      v-if="appear"
    >
      {{msg}}
    </v-snackbar>
    <v-data-table
      :headers="headers"
      :items="intake"
      sort-by="calories"
      class="elevation-1"
      :loading="loading"
    >
      <template v-slot:item.id="{item}">
        <v-chip small :color="`#9c9c9c`" style="color: white; font-weight: 600; width: 25px; padding: 8px;">
          {{intake.map(function(x) {return x.id; }).indexOf(item.id)+1}}
        </v-chip>
      </template>
      <template v-slot:item.title="{ item }">
        <input type="text" style="width: auto;" :value="item.title" :id="'title'+item.id" @dblclick="editMode(item.id,'title')" @blur="blurMode(item.id,'title')" @keyup.enter="blurMode(item.id,'title')"  readonly/>
      </template>
      <template v-slot:item.intake="{ item }">
        <v-chip color="blue" text-color="white">
          {{item.intake}}
        </v-chip>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-btn small :color="'#40E583'" style="color: #ffffff; margin-top: 10px !important; padding-right: 5px; margin-right: 2px; text-transform: none;" v-if="item.status === '1'" @click="CloseIntake(item.id,item.title,2)">
          Opened
        </v-btn>
        <v-btn small color="red" style="color: #ffffff; margin-top: 10px !important; padding-right: 5px; margin-right: 2px;text-transform: none;" v-if="item.status === '0'" @click="CloseIntake(item.id,item.title,1)">
          Closed
        </v-btn>
        <v-btn small color="blue" style="color: #ffffff; margin-top: 10px !important; padding-right: 5px; margin-right: 2px; text-transform: none;" v-if="item.status === '1' && item.ext === 'extend'" @click="CloseIntake(item.id,item.title,3)">
          Extend
        </v-btn>
      </template>
      <template v-slot:top>
        <v-toolbar
          flat
        >
          <v-toolbar-title>Available Intakes</v-toolbar-title>
          <v-divider
            class="mx-4"
            inset
            vertical
          ></v-divider>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            dark
            class="mb-2"
            @click="onModal"
            style="text-transform: none"
          >
            Create Intake
          </v-btn>
        </v-toolbar>
      </template>
    </v-data-table>
    <CreateIntake :intakes="showup" @quitModal="cancel($event)" @getResponse="responseData($event)"></CreateIntake>
    <v-dialog
      v-model="closingModal"
      max-width="500px"
    >
      <v-card>
        <v-card-title>
          <span class="headline">{{heading}}</span>
        </v-card-title>
        <b-container>
          <v-row>
            <input type="hidden" v-model="id">
            <v-col
              cols="12"
              sm="12"
              md="12"
            >
              <v-text-field
                v-model="title"
                readonly
                dense
                outlined
                disabled
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              sm="12"
              md="12"
            >
              <v-text-field
                v-model="extend"
                label="Enter days to extend"
                name="Extend"
                type="text"
                v-validate="'required'"
                v-if="toExtend"
                outlined
                dense
              ></v-text-field>
              <span style="color: red;"
                v-show="errors.has('Extend')">{{ errors.first('Extend') }}</span>
            </v-col>
            <v-col
              cols="12"
              sm="12"
              md="12"
            >
              <v-text-field
                v-model="password"
                label="Enter password to confirm"
                name="Password"
                type="password"
                v-validate="'required'"
                outlined
                dense
              ></v-text-field>
              <span style="color: red;"
                v-show="errors.has('Password')">{{ errors.first('Password') }}</span>
            </v-col>
          </v-row>
        </b-container>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="quit"
            style="text-transform: none;"
            outlined
          >
            Cancel
          </v-btn>
          <v-btn
            color="blue darken-1"
            text
            @click="terminateIntake"
            style="text-transform: none;"
            outlined
          >
            {{btnTitle}}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-app>
</template>

<script>
import CreateIntake from './CreateIntake'
export default {
  name: 'Intakes',
  components: {
    CreateIntake,
  },
  data () {
    return {
      headers: [
        { text: '#', value: 'id' },
        { text: 'Title', value: 'title' },
        { text: 'Start date', value: 'start_date' },
        { text: 'End date', value: 'end_date' },
        { text: 'Student registered', value: 'intake' },
        { text: '', value: 'actions' },
      ],
      showup: false,
      intake: [],
      menu3: false,
      menu2: false,
      modal: false,
      startId: '',
      endDate: '',
      modalEnd: false,
      startdate: '',
      closingModal: false,
      password: '',
      title: '',
      id: '',
      heading: '',
      btnTitle: '',
      toExtend: false,
      extend: '',
      loading: true,
      msg: '',
      appear: false,
    }
  },
  created () {
    this.fetchIntakes()
  },
  methods: {
    editMode: function (id, key) {
      var intk = document.getElementById(key + '' + id)
      intk.focus()
      intk.readOnly = false
      intk.classList.add('editMode')
    },
    blurMode (id, key) {
      var intk = document.getElementById(key + '' + id)
      intk.readOnly = true
      intk.classList.remove('editMode')
      this.editClass(id, key, intk.value)
    },
    async editClass (id, key, value) {
      if (localStorage.getItem('logged_user')) { this.logger = JSON.parse(localStorage.getItem('logged_user')) }
      this.header = this.logger.accessToken
      const data = {
        id: id,
        key: key,
        value: value,
      }
      try {
        await this.axios({
          url: 'manipulate_intake/0',
          method: 'post',
          data: data,
          headers: {
            Authorization: 'Bearer ' + this.header,
          },
        })
      } catch (e) {
        if (e.response && e.response.status === 403) {
          console.log(e)
        } else {
          console.log(e)
        }
      }
    },
    closeModalEnd () {
      this.modalEnd = false
    },
    closeModal () {
      this.modal = false
    },
    cancel (emitted) {
      this.showup = emitted
    },
    onModal () {
      this.showup = true
    },
    responseData (event) {
      this.msg = 'Intake created'
      this.appear = true
      this.intake.push(event)
    },
    async fetchIntakes () {
      if (localStorage.getItem('logged_user')) {
        this.logger = JSON.parse(localStorage.getItem('logged_user'))
      }
      this.header = this.logger.accessToken
      try {
        const res = await this.axios({
          url: 'get_intakes',
          method: 'get',
          headers: {
            Authorization: 'Bearer ' + this.header,
          },
        })
        if (res.status === 200) {
          this.loading = false
          this.intake = res.data
          console.log(res.data)
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.$router.push({ name: 'login-page' })
        } else {
          console.log(error)
        }
      }
    },
    callModal (id, key) {
      if (key === 'start_date') {
        this.startId = id
        this.modal = true
      } else if (key === 'end_date') {
        this.endId = id
        this.modalEnd = true
      }
    },
    async edit (key) {
      let id = 0
      let value = ''
      if (key === 'start_date') {
        id = this.startId
        value = this.startdate
      } else if (key === 'end_date') {
        id = this.endId
        value = this.endDate
      }
      if (localStorage.getItem('logged_user')) { this.logger = JSON.parse(localStorage.getItem('logged_user')) }
      this.header = this.logger.accessToken
      const data = {
        id: id,
        key: key,
        value: value,
      }
      try {
        const response = await this.axios({
          url: 'manipulate_intake/0',
          method: 'post',
          data: data,
          headers: {
            Authorization: 'Bearer ' + this.header,
          },
        })
        if (response.status === 200) {
          this.fetchIntakes()
          if (key === 'start_date') {
            this.startdate = ''
            this.modal = false
          } else if (key === 'end_date') {
            this.endDate = ''
            this.modalEnd = false
          }
        }
      } catch (e) {
        if (e.response && e.response.status === 403) {
          console.log(e)
        } else if (e.response && e.response.status === 400) {
          this.loading = false
        } else {
          console.log(e)
        }
      }
    },
    CloseIntake (id, title, status) {
      this.appear = false
      if (status === 2) {
        this.closingModal = true
        this.btnTitle = 'Terminate'
        this.heading = 'Closing Intake'
      } else if (status === 1) {
        this.closingModal = true
        this.btnTitle = 'Open'
        this.heading = 'Open Intake'
      } else if (status === 3) {
        this.closingModal = true
        this.btnTitle = 'Extend'
        this.heading = 'Extend Intake duration'
        this.toExtend = true
      }
      this.id = id
      this.title = title
    },
    quit () {
      this.password = ''
      this.closingModal = false
      this.toExtend = false
    },
    async terminateIntake () {
      this.msg = 'Intake terminated'
      if (localStorage.getItem('logged_user')) {
        this.logger = JSON.parse(localStorage.getItem('logged_user'))
      }
      this.header = this.logger.accessToken
      if (this.btnTitle === 'Terminate') {
        const data = {
          id: this.id,
          password: this.password,
        }
        try {
          const res = await this.axios({
            url: 'close_intake',
            method: 'post',
            data: data,
            headers: {
              Authorization: 'Bearer ' + this.header,
            },
          })
          if (res.status === 200) {
            this.appear = true
            this.quit()
            this.fetchIntakes()
          } else {
            alert('Data not saved')
          }
        } catch (e) {
          if (e.response && e.response.status === 401) {
            this.$router.push({ name: 'login-page' })
          } else if (e.response && e.response.status === 400) {
            alert(e.response.data.messages)
          } else {
            console.log(e)
          }
        }
      } else if (this.btnTitle === 'Open') {
        this.msg = 'Intake opened'
        const data = {
          id: this.id,
          password: this.password,
        }
        try {
          const res = await this.axios({
            url: 'open_intake',
            method: 'post',
            data: data,
            headers: {
              Authorization: 'Bearer ' + this.header,
            },
          })
          if (res.status === 200) {
            this.appear = true
            this.quit()
            this.fetchIntakes()
          } else {
            alert('Data not saved')
          }
        } catch (e) {
          if (e.response && e.response.status === 401) {
            this.$router.push({ name: 'login-page' })
          } else if (e.response && e.response.status === 400) {
            alert(e.response.data.messages)
          } else {
            console.log(e)
          }
        }
      } else if (this.btnTitle === 'Extend') {
        this.msg = 'Intake Extended'
        const data = {
          id: this.id,
          extend: this.extend,
          password: this.password,
        }
        try {
          const res = await this.axios({
            url: 'extend_intake',
            method: 'post',
            data: data,
            headers: {
              Authorization: 'Bearer ' + this.header,
            },
          })
          if (res.status === 200) {
            this.appear = true
            this.quit()
            this.fetchIntakes()
          } else {
            alert('Data not saved')
          }
        } catch (e) {
          if (e.response && e.response.status === 401) {
            this.$router.push({ name: 'login-page' })
          } else if (e.response && e.response.status === 400) {
            alert(e.response.data.messages)
          } else {
            console.log(e)
          }
        }
      }
    },
  },
}
</script>

<style scoped>
  .editMode {
    padding: 3px;
    background-color: #fdf9ff;
    font-size: 14px;
    border: 1px solid;
    border-radius: 3px;
  }
</style>>
